import React from 'react'
import { Container } from '../components/containers/Container'
import Layout from '../components/layout'
import Seo from '../components/Seo'

const StoreClosed = ({ location }) => (
  <Layout>
    <Seo title="404: Store Closed" location={location.pathname} />
    <Container className="wrapper">
      <h1>Store Closed</h1>
      <p>
        Unfortunately, this store has been closed, please visit the locations
        page to see our other stores in your area.
      </p>
    </Container>
  </Layout>
)

export default StoreClosed